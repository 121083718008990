import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Handle,
  // NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
} from 'reactflow';
import styled from 'styled-components';
import { boxShadows, colors, fonts } from '../../styles/variables';
import { FlexCenterAll } from '../../styles/library/layoutStyles';
import { check, pencil, timesDarkGrey } from '../../assets';
import { useState } from 'react';
import TextInput from '../Inputs/TextInput';
import { UseOutsideClick } from '../../utils';

const handleStyle = {
  backgroundColor: colors.paleGrey,
};

const TextNode = ({ data }) => {
  const ref = useRef();
  const { deleteElements, setNodes } = useReactFlow();
  const [showTextInput, setShowTextInput] = useState(false);
  const [text, setText] = useState('');

  UseOutsideClick(ref, () => {
    if (showTextInput) {
      setShowTextInput(false);
    }
  });

  useEffect(() => {
    if (data?.label) {
      setText(data?.label);
    }
  }, [data?.label]);

  useEffect(() => {
    if (data.isNew) {
      setTimeout(() => {
        setNodes((nodes) =>
          [...nodes].map((value) => {
            const node = { ...value };
            if (node.id === data.id) {
              delete node.data.isNew;
            }
            return node;
          })
        );
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isNew]);

  const transformNodes = (nodes, textValue) => {
    return [...nodes].map((value) => {
      const node = { ...value };
      if (node.id === data.id) {
        node.data.label = textValue;
      }
      return node;
    });
  };

  const onTextChange = (e) => {
    const newValue = e.currentTarget.value;
    setText(newValue);
    setNodes((nodes) => transformNodes(nodes, newValue));
  };

  const onEdit = () => {
    setShowTextInput(!showTextInput);
  };

  const onRemove = (text) => {
    if (showTextInput) {
      setShowTextInput(false);
    } else {
      deleteElements({ nodes: [{ id: text.id }] });
    }
  };

  const toolBarActions = [
    {
      value: showTextInput ? 'done' : 'edit',
      icon: showTextInput ? check : pencil,
      onClick: onEdit,
      title: showTextInput ? 'Done Editing' : 'Edit Text Value',
    },
    {
      value: 'remove',
      icon: timesDarkGrey,
      onClick: onRemove,
      title: showTextInput ? 'Close Edit View' : 'Remove from Map',
      style: { opacity: 0.4, width: '10px' },
    },
  ];

  return (
    <>
      {/* <NodeResizer minWidth={100} minHeight={30} /> */}
      <ToolbarContainer
        id="text-toolbar"
        position={data.toolbarPosition}
        align="center"
        offset={10}
      >
        {toolBarActions.map((action) => {
          return (
            <ActionButton
              key={action.value}
              onClick={() => action.onClick(data)}
              id={`${action.value}-button`}
              title={action.title}
            >
              <img
                id={`${action.value}-icon`}
                src={action.icon}
                alt={action.value}
                data-image={`${action.value}-${data.id}`}
                style={{ ...action.style }}
              />
            </ActionButton>
          );
        })}
      </ToolbarContainer>
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        id="n"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={handleStyle}
        id="e"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={handleStyle}
        id="w"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        id="s"
      />
      <TextNodeContainer
        $isEditing={showTextInput}
        ref={ref}
        $isNew={data.isNew}
      >
        {showTextInput ? (
          <>
            {/* <TextArea value={text} onChange={(e) => onTextChange(e)} /> */}
            <TextInput
              placeholder={data.label}
              maxLength="100"
              name={data.id}
              id={data.id}
              value={text}
              autoFocus={true}
              onChange={(e) => onTextChange(e)}
            />
          </>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </TextNodeContainer>
    </>
  );
};

const ToolbarContainer = styled(NodeToolbar)`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: ${boxShadows.boxShadowAMRSelected};
  border-radius: 50px;
  padding: 3px 2px;
`;

const ActionButton = styled.button`
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin: 0 2px;
  position: relative;
  ${FlexCenterAll};
  img {
    width: 12px;
    height: 12px;
    opacity: 0.6;
  }
  &:hover {
    background: ${colors.lightGrey};
    transform: scale(1.01);
  }
`;

const TextNodeContainer = styled.div`
  border: 1px solid ${colors.paleGrey};
  padding: ${(props) => (props.$isEditing ? '5px 5px' : '12px 20px')};
  border-radius: 3px;
  max-width: 200px;
  word-break: break-word;
  box-shadow: ${boxShadows.boxShadow};
  ${FlexCenterAll}
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
  text-align: center;
  background: ${(props) => (props.$isNew ? colors.paleBlue : colors.white)};
  ${'' /* background: ${colors.paleBlue}; */}
  ${'' /* color: ${colors.white}; */}
  ${'' /* background: ${colors.darkGrey}; */}
  ${'' /* text-transform: uppercase; */}
  input, textarea {
    padding: 6px 8px;
    font-size: 14px;
    font-weight: ${fonts.semiBold};
  }
`;

// const TextArea = styled.textarea`
//   border: 1px solid ${inputColors.border};
//   color: ${colors.darkGrey};
//   resize: none;
//   border-radius: 3px;
//   height: 100%;
//   width: 200px;
//   max-height: 500px;
//   min-height: ${(props) => (props.minHeight ? props.minHeight : '80px')};
// `;

TextNode.propTypes = {
  data: PropTypes.object,
};

export default memo(TextNode);
