/* eslint-disable array-callback-return */
import { GenerateSouthWestItems, GenerateSouthEastItems } from '..';
import { MergeArrays, SortInstrumentPriority } from '../transformers';
import {
  CalculateMultipleCustodianValues,
  CalculateMultipleOwnerValues,
  CalculateValues,
} from './calculate';
import {
  FilterSplitMemberInstruments,
  FilterJointInstruments,
  GetEntityInstruments,
  FilterMemberInstruments,
  RemoveDuplicates,
  GetMemberEntities,
  FindIntersect,
  HasNoOwners,
  HasNoOwnersCategory,
  FindCustodianAssets,
  FindCustodianAssetsMultiple,
  HasAssociatedAsset,
} from './filters';
import { GenerateRollupItems } from './rollup';

export const GenerateSouthItems = (
  membersList,
  entityList,
  liabilities,
  assets,
  insurances,
  incomes,
  selectedMembers,
  amrShowEntity,
  amrShowCustodian,
  showEntityRollup,
  allInstrumentCategories,
  expandAllRollup
) => {
  if (selectedMembers && selectedMembers.length === 2) {
    const firstMemEntities = GetMemberEntities(
      entityList,
      selectedMembers[0].id
    );
    const secondMemEntities = GetMemberEntities(
      entityList,
      selectedMembers[1].id
    );
    const memberLiabilities = FilterSplitMemberInstruments(
      liabilities,
      selectedMembers[0].id
    );
    const jointLiabilities = FilterJointInstruments(
      memberLiabilities,
      selectedMembers[1].id
    );

    const memberAssets = FilterSplitMemberInstruments(
      assets,
      selectedMembers[0].id
    );
    const jointAssets = FilterJointInstruments(
      memberAssets,
      selectedMembers[1].id
    );
    const selectedMemIds = selectedMembers.map((mem) => mem.id);

    let updatedAssets = jointAssets.map((asset) => {
      let liabilityList = liabilities;
      if (showEntityRollup) {
        liabilityList = liabilities.filter((lia) => !lia.owned_by_entity);
      }
      let matching = liabilityList.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          item.owned_by_entity = false;
        } else {
          item.owned_by_entity = true;
        }
        return item;
      });
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      asset.linked_items = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      return asset;
    });
    //If we want to show percentage calc - if not remove
    updatedAssets = CalculateMultipleOwnerValues(
      selectedMembers,
      updatedAssets
    );

    const nonRealEstateAssets = updatedAssets.filter(
      (asset) => asset.category !== 'residence'
    );
    const realEstateAssets = updatedAssets.filter(
      (asset) => asset.category === 'residence'
    );

    const assetIds = jointAssets.map((asset) => asset.id);

    let entityNonRealEstate = [];
    let entityIncomes = [];
    let entityLiabilities = [];
    let entityInsurances = [];
    let entityRealEstate = [];
    let entityAssetIds = [];
    if (amrShowEntity) {
      const cashValueInsurances = insurances.filter(
        (insurance) => insurance.cash_value > 0
      );
      const realEstateAssets = assets.filter(
        (asset) => asset.category === 'residence'
      );
      let nonRealEstateAssets = [];
      if (showEntityRollup) {
        nonRealEstateAssets = assets.filter(
          (asset) => asset.category === 'nonretirement'
        );
      } else {
        nonRealEstateAssets = assets.filter(
          (asset) => asset.category !== 'residence'
        );
      }

      const firstMemEntityAssets = GetEntityInstruments(
        nonRealEstateAssets,
        firstMemEntities
      );
      const firstMemEntityIncomes = GetEntityInstruments(
        incomes,
        firstMemEntities
      );
      const firstMemEntityLiabilities = GetEntityInstruments(
        liabilities,
        firstMemEntities
      );
      const firstMemEntityInsurances = GetEntityInstruments(
        cashValueInsurances,
        firstMemEntities
      );
      const firstMemEntityRealEstate = GetEntityInstruments(
        realEstateAssets,
        firstMemEntities
      );

      const secondMemEntityAssets = GetEntityInstruments(
        nonRealEstateAssets,
        secondMemEntities
      );
      const secondMemEntityIncomes = GetEntityInstruments(
        incomes,
        secondMemEntities
      );
      const secondMemEntityLiabilities = GetEntityInstruments(
        liabilities,
        secondMemEntities
      );
      const secondMemEntityInsurances = GetEntityInstruments(
        cashValueInsurances,
        secondMemEntities
      );
      const secondMemEntityRealEstate = GetEntityInstruments(
        realEstateAssets,
        secondMemEntities
      );
      entityNonRealEstate = FindIntersect(
        firstMemEntityAssets,
        secondMemEntityAssets
      );
      entityIncomes = FindIntersect(
        firstMemEntityIncomes,
        secondMemEntityIncomes
      );
      entityLiabilities = FindIntersect(
        firstMemEntityLiabilities,
        secondMemEntityLiabilities
      );
      entityInsurances = FindIntersect(
        firstMemEntityInsurances,
        secondMemEntityInsurances
      );
      entityRealEstate = FindIntersect(
        firstMemEntityRealEstate,
        secondMemEntityRealEstate
      );
      const entityNonRealEstateIds = entityNonRealEstate.map((item) => item.id);
      const entityRealEstateIds = entityRealEstate.map((item) => item.id);

      entityNonRealEstate = entityNonRealEstate.map((asset) => {
        let matching = liabilities.filter(
          (liability) => liability.associated === asset.id
        );
        const propCas = insurances.filter((insurance) =>
          insurance.assets.includes(asset.id)
        );
        matching = [...matching, ...propCas];
        matching = matching.map((item) => {
          const copied = { ...item };
          // if (item.instrument_type === 'liability') {
          //   copied.owned_by_entity = true;
          //   return copied;
          // }
          const owners = item.members.reduce((acc, curr) => {
            if (curr.relation === 'owner') {
              return [...acc, curr.id];
            }
            return acc;
          }, []);
          if (owners.some((o) => selectedMemIds.includes(o))) {
            copied.owned_by_entity = false;
          } else {
            copied.owned_by_entity = true;
          }
          return copied;
        });
        asset.owned_by_another = false;
        asset.owned_by_entity = true;
        asset.linked_items = CalculateMultipleOwnerValues(
          selectedMembers,
          matching
        );
        return asset;
      });

      entityRealEstate = entityRealEstate.map((asset) => {
        let matching = liabilities.filter(
          (liability) => liability.associated === asset.id
        );
        const propCas = insurances.filter((insurance) =>
          insurance.assets.includes(asset.id)
        );
        matching = [...matching, ...propCas];

        matching = matching.map((item) => {
          const copied = { ...item };
          // if (item.instrument_type === 'liability') {
          //   copied.owned_by_entity = true;
          //   return copied;
          // }
          const owners = item.members.reduce((acc, curr) => {
            if (curr.relation === 'owner') {
              return [...acc, curr.id];
            }
            return acc;
          }, []);
          if (owners.some((o) => selectedMemIds.includes(o))) {
            copied.owned_by_entity = false;
          } else {
            copied.owned_by_entity = true;
          }
          return copied;
        });
        asset.owned_by_another = false;
        asset.owned_by_entity = true;
        asset.linked_items = CalculateMultipleOwnerValues(
          selectedMembers,
          matching
        );
        return asset;
      });

      entityInsurances = entityInsurances.map((insurance) => {
        let updated = { ...insurance };
        updated.calculated = insurance.cash_value;
        updated.show_cash_value = true;
        return updated;
      });

      entityAssetIds = [
        ...entityRealEstateIds,
        ...entityNonRealEstateIds,
        ...assetIds,
      ];

      entityLiabilities = entityLiabilities.filter(
        (liability) => !entityAssetIds.includes(liability.associated)
      );
    }

    const allAssetIds = [...entityAssetIds, ...assetIds];

    let filteredLiabilities = jointLiabilities.filter(
      (liability) => !allAssetIds.includes(liability.associated)
    );
    filteredLiabilities = filteredLiabilities.map((liability) => {
      liability.owned_by_another = false;
      liability.owned_by_entity = false;
      return liability;
    });
    //If we want to show percentage calc - if not remove
    filteredLiabilities = CalculateMultipleOwnerValues(
      selectedMembers,
      filteredLiabilities
    );

    const memberInsurances = FilterSplitMemberInstruments(
      [...insurances],
      selectedMembers[0].id
    );
    const filteredInsurances = memberInsurances.filter(
      (insurance) => insurance.cash_value > 0
    );

    let jointInsurances = FilterJointInstruments(
      filteredInsurances,
      selectedMembers[1].id,
      'owner',
      showEntityRollup
    );

    jointInsurances = jointInsurances.map((insurance) => {
      insurance.owned_by_another = false;
      insurance.owned_by_entity = false;
      return insurance;
    });
    //If we want to show percentage calc - if not remove
    jointInsurances = CalculateMultipleOwnerValues(
      selectedMembers,
      jointInsurances,
      'owner',
      true
    );
    // @recent filtered out entity duplicates
    let remainingEntityAssets = entityNonRealEstate.filter((asset) => {
      if (!nonRealEstateAssets.find((item) => item.id === asset.id)) {
        return asset;
      }
    });
    let remainingEntityLiability = entityLiabilities.filter((liability) => {
      if (!filteredLiabilities.find((item) => item.id === liability.id)) {
        return liability;
      }
    });
    if (showEntityRollup) {
      entityInsurances = [];
      remainingEntityAssets = [];
      remainingEntityLiability = [];
      entityIncomes = [];
    }

    const groupedNonRealEstate = SortInstrumentPriority([
      ...nonRealEstateAssets,
      ...remainingEntityAssets,
    ]);

    const groupedIncomes = SortInstrumentPriority([...entityIncomes]);
    let groupedLiabilities = SortInstrumentPriority([
      ...filteredLiabilities,
      ...remainingEntityLiability,
    ]);

    let groupedInsurances = SortInstrumentPriority([
      ...jointInsurances,
      ...entityInsurances,
    ]);
    //LINKED LIABILITY CHANGES
    const groupedInsurancesIds = groupedInsurances.map((item) => item.id);

    groupedInsurances = groupedInsurances.map((insurance) => {
      const updated = { ...insurance };
      if (!insurance.is_rollup_parent) {
        const linked = groupedLiabilities.filter(
          (liability) => liability.associated === insurance.id
        );
        updated.linked_items = linked.map((item) => {
          return { ...item };
        });
      }
      return updated;
    });

    groupedLiabilities = groupedLiabilities.filter(
      (liability) => !groupedInsurancesIds.includes(liability.associated)
    );
    //LINKED LIABILITY CHANGES

    let remainingEntityRealEstate = entityRealEstate.filter((asset) => {
      if (!realEstateAssets.find((item) => item.id === asset.id)) {
        return asset;
      }
    });
    if (showEntityRollup) {
      remainingEntityRealEstate = [];
    }

    const groupedRealEstate = SortInstrumentPriority([
      ...realEstateAssets,
      ...remainingEntityRealEstate,
    ]);

    return [
      ...groupedNonRealEstate,
      ...groupedIncomes,
      ...groupedLiabilities,
      ...groupedInsurances,
      ...groupedRealEstate,
    ];
  } else if (selectedMembers && selectedMembers.length === 0) {
    const residenceAssets = HasNoOwnersCategory(assets, 'residence');
    const noOwnerInsurance = HasNoOwners([...insurances]);
    const noOwnerLiabilities = HasNoOwners(liabilities);

    const cashValueInsurances = noOwnerInsurance.filter((insurance) => {
      if (
        insurance.cash_value !== null &&
        insurance.cash_value !== 0 &&
        insurance.cash_value !== ''
      ) {
        insurance.owned_by_entity = false;
        insurance.owned_by_another = false;
        insurance.show_cash_value = true;
        insurance.calculated = insurance.cash_value;
        //LINKED LIABILITY CHANGES
        let matching = noOwnerLiabilities.filter(
          (liability) => liability.associated === insurance.id
        );
        insurance.linked_items = matching.map((item) => {
          const copied = { ...item };
          copied.owned_by_another = false;
          copied.owned_by_entity = false;
          return copied;
        });
        //LINKED LIABILITY CHANGES
        return insurance;
      }
    });

    const updatedAssets = residenceAssets.map((asset) => {
      let matching = noOwnerLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      asset.linked_items = [...matching, ...propCas].map((item) => {
        const copied = { ...item };
        copied.owned_by_another = false;
        copied.owned_by_entity = false;
        return copied;
      });
      asset.owned_by_entity = false;
      asset.owned_by_another = false;
      return asset;
    });

    return SortInstrumentPriority([...updatedAssets, ...cashValueInsurances]);
  } else if (
    selectedMembers &&
    selectedMembers.length === membersList.length &&
    membersList.length !== 1
  ) {
    const residenceAssets = assets.filter((asset) => {
      if (asset.category === 'residence') {
        return asset;
      }
    });
    const memberLiabilities = FilterMemberInstruments(
      selectedMembers,
      liabilities
    );

    let updatedAssets = residenceAssets.map((asset) => {
      let matching = memberLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        copied.owned_by_another = false;
        copied.owned_by_entity = false;
        return copied;
      });
      const calculated = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      asset.linked_items = calculated;
      return asset;
    });
    updatedAssets = CalculateMultipleOwnerValues(
      selectedMembers,
      updatedAssets
    );

    let cashValueInsurances = [...insurances].filter((insurance) => {
      if (
        insurance.cash_value !== null &&
        insurance.cash_value !== 0 &&
        insurance.cash_value !== ''
      ) {
        insurance.owned_by_another = false;
        insurance.owned_by_entity = false;
        return insurance;
      }
    });
    cashValueInsurances = CalculateMultipleOwnerValues(
      selectedMembers,
      cashValueInsurances,
      'owner',
      true
    );
    const assetIds = updatedAssets.map((item) => item.id);

    const associatedLiabilities = memberLiabilities.filter((liability) => {
      if (!assetIds.includes(liability.associated)) {
        liability.owned_by_another = false;
        liability.owned_by_entity = false;
        return liability;
      }
    });

    const southEastItems = GenerateSouthEastItems(
      membersList,
      entityList,
      assets,
      liabilities,
      insurances,
      selectedMembers,
      amrShowEntity,
      amrShowCustodian,
      showEntityRollup,
      allInstrumentCategories,
      expandAllRollup
    );
    const eastLiabilities = southEastItems.reduce((acc, curr) => {
      if (curr.hasOwnProperty('linked_items')) {
        const linkedItemsIds = curr.linked_items.map((item) => item.id);
        return [...acc, ...linkedItemsIds];
      }
      return acc;
    }, []);
    const southWestItems = GenerateSouthWestItems(
      membersList,
      entityList,
      assets,
      liabilities,
      insurances,
      selectedMembers,
      amrShowEntity,
      amrShowCustodian,
      showEntityRollup,
      allInstrumentCategories,
      expandAllRollup
    );
    const westLiabilities = southWestItems.reduce((acc, curr) => {
      if (curr.hasOwnProperty('linked_items')) {
        const linkedItemsIds = curr.linked_items.map((item) => item.id);
        return [...acc, ...linkedItemsIds];
      }
      return acc;
    }, []);
    const linkedLiabilities = [...eastLiabilities, ...westLiabilities];
    let finalLiabilities = associatedLiabilities.filter(
      (liability) => !linkedLiabilities.includes(liability.id)
    );
    //LINKED LIABILITY CHANGES
    cashValueInsurances = cashValueInsurances.map((insurance) => {
      const updated = { ...insurance };
      const linked = finalLiabilities.filter(
        (liability) => liability.associated === insurance.id
      );
      updated.linked_items = linked.map((item) => {
        return { ...item };
      });
      return updated;
    });
    const insuranceIds = cashValueInsurances.map((item) => item.id);
    finalLiabilities = finalLiabilities.filter(
      (liability) => !insuranceIds.includes(liability.associated)
    );
    //LINKED LIABILITY CHANGES
    finalLiabilities = CalculateMultipleOwnerValues(
      selectedMembers,
      finalLiabilities
    );

    return [
      ...SortInstrumentPriority(cashValueInsurances),
      ...SortInstrumentPriority(finalLiabilities),
      ...SortInstrumentPriority(updatedAssets),
    ];
  } else if (selectedMembers.length === 1) {
    const residenceAssets = assets.filter(
      (asset) => asset.category === 'residence'
    );

    const memEntities = GetMemberEntities(entityList, selectedMembers[0].id);

    let custodianAssets = [];
    let custodianAssetsIds = [];
    if (amrShowCustodian) {
      custodianAssets = FindCustodianAssets(
        residenceAssets,
        selectedMembers[0].id
      );
      custodianAssetsIds = custodianAssets.map((asset) => asset.id);
    }

    let entityAssets = [];
    let entityAssetsIds = [];
    let entityLiabilities = [];
    if (amrShowEntity) {
      entityAssets = GetEntityInstruments(residenceAssets, memEntities);
      entityLiabilities = GetEntityInstruments(liabilities, memEntities);
      entityLiabilities = entityLiabilities.map((liability) => {
        const updated = { ...liability };
        updated.owned_by_another = false;
        updated.owned_by_entity = true;
        return updated;
      });
      entityAssetsIds = entityAssets.map((asset) => asset.id);
    }
    let memberResidenceAssets = FilterMemberInstruments(
      selectedMembers,
      residenceAssets
    );
    const matchedAssetIds = memberResidenceAssets.map((asset) => asset.id);
    const mergedAssetIds = MergeArrays(
      matchedAssetIds,
      custodianAssetsIds,
      entityAssetsIds
    );

    const memberLiabilities = FilterMemberInstruments(
      selectedMembers,
      liabilities
    );
    const mergedLiabilities = MergeArrays(memberLiabilities, entityLiabilities);

    let filteredLiabilities = HasAssociatedAsset(
      mergedLiabilities,
      mergedAssetIds
    );
    filteredLiabilities = RemoveDuplicates(filteredLiabilities);

    custodianAssets = custodianAssets.map((asset) => {
      let matching = memberLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      asset.owned_by_entity = false;
      asset.owned_by_another = true;
      asset.linked_items = CalculateValues(matching, selectedMembers[0].id);
      return asset;
    });

    entityAssets = entityAssets.map((asset) => {
      const copiedAsset = { ...asset };
      let matching = liabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      copiedAsset.linked_items = CalculateValues(
        matching,
        selectedMembers[0].id
      );
      copiedAsset.owned_by_another = false;
      copiedAsset.owned_by_entity = true;
      return copiedAsset;
    });

    let entityRollupAssets = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupAssets = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        entityAssets,
        allInstrumentCategories,
        'asset',
        'south',
        expandAllRollup
      );
      let rollupInstruments = GetEntityInstruments(
        entityAssets,
        rollupEntities
      );
      entityAssets = entityAssets.filter((asset) => {
        if (!rollupInstruments.some((i) => i.id === asset.id)) {
          return asset;
        }
      });
    }
    custodianAssets = custodianAssets.filter((asset) => {
      if (!entityAssetsIds.includes(asset.id)) {
        return asset;
      }
    });

    let updatedAssets = memberResidenceAssets.map((asset) => {
      let matching = filteredLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      const calculated = CalculateValues(matching, selectedMembers[0].id);
      asset.linked_items = calculated;
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      return asset;
    });

    updatedAssets = CalculateValues(updatedAssets, selectedMembers[0].id);
    updatedAssets = MergeArrays(updatedAssets, custodianAssets);

    const cashValueInsurances = [...insurances].filter(
      (insurance) =>
        insurance.cash_value !== null &&
        insurance.cash_value !== 0 &&
        insurance.cash_value !== ''
    );
    let memberCashValueInsurances = FilterMemberInstruments(
      selectedMembers,
      cashValueInsurances
    );
    let entityInsurances = [];
    if (amrShowEntity) {
      entityInsurances = GetEntityInstruments(cashValueInsurances, memEntities);
      entityInsurances = entityInsurances.map((insurance) => {
        const copied = { ...insurance };
        copied.owned_by_another = false;
        copied.owned_by_entity = true;
        copied.calculated = insurance.cash_value;
        copied.show_cash_value = true;
        return copied;
      });
    }
    memberCashValueInsurances = memberCashValueInsurances.map((insurance) => {
      insurance.owned_by_another = false;
      insurance.owned_by_entity = false;
      return insurance;
    });
    memberCashValueInsurances = CalculateValues(
      memberCashValueInsurances,
      selectedMembers[0].id
    );
    let entityRollupInsurances = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupInsurances = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        entityInsurances,
        allInstrumentCategories,
        'insurance',
        'south',
        expandAllRollup
      );
      let rollupInstruments = GetEntityInstruments(
        entityInsurances,
        rollupEntities
      );
      entityInsurances = entityInsurances.filter((ins) => {
        if (!rollupInstruments.some((i) => i.id === ins.id)) {
          return ins;
        }
      });
    }

    entityLiabilities = entityLiabilities.filter(
      (liability) =>
        ![...entityAssetsIds, ...mergedAssetIds].includes(liability.associated)
    );

    const southEastItems = GenerateSouthEastItems(
      membersList,
      entityList,
      assets,
      liabilities,
      insurances,
      selectedMembers,
      amrShowEntity,
      amrShowCustodian,
      showEntityRollup,
      allInstrumentCategories,
      expandAllRollup
    );
    const eastLiabilities = southEastItems.reduce((acc, curr) => {
      if (curr.hasOwnProperty('linked_items')) {
        const linkedItemsIds = curr.linked_items.map((item) => item.id);
        return [...acc, ...linkedItemsIds];
      }
      return acc;
    }, []);
    const southWestItems = GenerateSouthWestItems(
      membersList,
      entityList,
      assets,
      liabilities,
      insurances,
      selectedMembers,
      amrShowEntity,
      amrShowCustodian,
      showEntityRollup,
      allInstrumentCategories,
      expandAllRollup
    );
    const westLiabilities = southWestItems.reduce((acc, curr) => {
      if (curr.hasOwnProperty('linked_items')) {
        const linkedItemsIds = curr.linked_items.map((item) => item.id);
        return [...acc, ...linkedItemsIds];
      }
      return acc;
    }, []);

    entityLiabilities = entityLiabilities.filter(
      (liability) =>
        ![
          // ...memberLiabilityIds,
          ...eastLiabilities,
          ...westLiabilities,
        ].includes(liability.id)
    );

    let entityRollupLiabilities = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      const allEntityLiabilities = GetEntityInstruments(
        liabilities,
        memEntities
      );

      entityRollupLiabilities = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        allEntityLiabilities,
        allInstrumentCategories,
        'liability',
        'south',
        expandAllRollup
      );
      let rollupInstruments = GetEntityInstruments(
        allEntityLiabilities,
        rollupEntities
      );
      entityLiabilities = entityLiabilities.filter((lia) => {
        if (!rollupInstruments.some((i) => i.id === lia.id)) {
          return lia;
        }
      });
      updatedAssets = updatedAssets.map((asset) => {
        const copied = { ...asset };
        const linked = asset?.linked_items.filter(
          (item) => !item.owned_by_entity
        );
        copied.linked_items = linked;
        return copied;
      });
    }
    let finalLiabilities = [];
    if (showEntityRollup) {
      const mergedAssetIds = MergeArrays(matchedAssetIds, custodianAssetsIds);
      finalLiabilities = memberLiabilities.filter(
        (liability) => !mergedAssetIds.includes(liability.associated)
      );
    } else {
      finalLiabilities = memberLiabilities.filter(
        (liability) => !mergedAssetIds.includes(liability.associated)
      );
    }

    //A linked liability to a entity owned instrument needs to be included in finalLiabilities
    //Here is where we need to check
    finalLiabilities = finalLiabilities.filter(
      (liability) =>
        ![...eastLiabilities, ...westLiabilities].includes(liability.id)
    );
    finalLiabilities = finalLiabilities.map((liability) => {
      const updated = { ...liability };
      updated.owned_by_another = false;
      updated.owned_by_entity = false;
      return updated;
    });
    finalLiabilities = CalculateValues(finalLiabilities, selectedMembers[0].id);

    let groupedInsurances = SortInstrumentPriority([
      ...memberCashValueInsurances,
      ...entityInsurances,
      ...entityRollupInsurances,
    ]);

    //LINKED LIABILITY CHANGES
    const groupedInsurancesIds = groupedInsurances.map((item) => item.id);
    //LINKED LIABILITY CHANGES
    let groupedLiabilities = SortInstrumentPriority([
      ...finalLiabilities,
      ...entityLiabilities,
      ...entityRollupLiabilities,
    ]);
    //LINKED LIABILITY CHANGES
    groupedInsurances = groupedInsurances.map((insurance) => {
      const updated = { ...insurance };
      if (!insurance.is_rollup_parent) {
        const linked = groupedLiabilities.filter(
          (liability) => liability.associated === insurance.id
        );
        updated.linked_items = linked.map((item) => {
          return { ...item };
        });
      }

      return updated;
    });
    groupedLiabilities = groupedLiabilities.filter(
      (liability) => !groupedInsurancesIds.includes(liability.associated)
    );
    //LINKED LIABILITY CHANGES
    const groupedAssets = SortInstrumentPriority([
      ...updatedAssets,
      ...entityAssets,
      ...entityRollupAssets,
    ]);

    return [...groupedInsurances, ...groupedLiabilities, ...groupedAssets];
  } else {
    const residenceAssets = assets.filter(
      (asset) => asset.category === 'residence'
    );
    const selectedMemIds = selectedMembers.map((mem) => mem.id);
    let memEntities = GetMemberEntities(entityList, false, selectedMembers);
    const selectedEntities = selectedMembers.filter(
      (mem) => mem.member_type === 'entity'
    );
    memEntities = memEntities.filter((entity) => {
      return !selectedEntities.includes(entity);
    });

    let custodianAssets = [];
    let custodianAssetsIds = [];
    if (amrShowCustodian) {
      custodianAssets = FindCustodianAssetsMultiple(
        residenceAssets,
        selectedMembers
      );
      custodianAssetsIds = custodianAssets.map((asset) => asset.id);
    }
    let entityAssets = [];
    let entityLiabilities = [];
    if (amrShowEntity) {
      entityAssets = GetEntityInstruments(residenceAssets, memEntities);
      entityLiabilities = GetEntityInstruments(liabilities, memEntities);
      entityLiabilities = entityLiabilities.map((liability) => {
        const updated = { ...liability };
        updated.owned_by_another = false;
        updated.owned_by_entity = true;
        return updated;
      });
    }

    let memberResidenceAssets = FilterMemberInstruments(
      selectedMembers,
      residenceAssets
    );
    const matchedAssetIds = memberResidenceAssets.map((asset) => asset.id);
    const mergedAssetIds = MergeArrays(matchedAssetIds, custodianAssetsIds);

    const memberLiabilities = FilterMemberInstruments(
      selectedMembers,
      liabilities
    );
    const mergedLiabilities = MergeArrays(memberLiabilities, entityLiabilities);

    let filteredLiabilities = HasAssociatedAsset(
      mergedLiabilities,
      mergedAssetIds
    );
    filteredLiabilities = RemoveDuplicates(filteredLiabilities);

    custodianAssets = custodianAssets.map((asset) => {
      let matching = memberLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      asset.owned_by_another = true;
      asset.owned_by_entity = false;
      asset.linked_items = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      return asset;
    });
    custodianAssets = CalculateMultipleCustodianValues(custodianAssets);

    entityAssets = entityAssets.map((asset) => {
      const updated = { ...asset };
      let matching = liabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      updated.linked_items = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      updated.owned_by_another = false;
      updated.owned_by_entity = true;
      return updated;
    });
    const entityAssetsIds = entityAssets.map((asset) => asset.id);

    let entityRollupAssets = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupAssets = GenerateRollupItems(
        selectedMemIds,
        rollupEntities,
        entityAssets,
        allInstrumentCategories,
        'asset',
        'south',
        expandAllRollup
      );
      let rollupInstruments = GetEntityInstruments(
        entityAssets,
        rollupEntities
      );
      entityAssets = entityAssets.filter((asset) => {
        if (!rollupInstruments.some((i) => i.id === asset.id)) {
          return asset;
        }
      });
    }
    custodianAssets = custodianAssets.filter((asset) => {
      if (!entityAssetsIds.includes(asset.id)) {
        return asset;
      }
    });

    let updatedAssets = memberResidenceAssets.map((asset) => {
      let matching = filteredLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      const calculated = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      asset.linked_items = calculated;
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      return asset;
    });

    updatedAssets = CalculateMultipleOwnerValues(
      selectedMembers,
      updatedAssets
    );
    updatedAssets = MergeArrays(updatedAssets, custodianAssets);

    const cashValueInsurances = insurances.filter(
      (insurance) =>
        insurance.cash_value !== null &&
        insurance.cash_value !== 0 &&
        insurance.cash_value !== ''
    );
    let memberCashValueInsurances = FilterMemberInstruments(
      selectedMembers,
      cashValueInsurances
    );

    let entityInsurances = [];
    if (amrShowEntity) {
      entityInsurances = GetEntityInstruments(cashValueInsurances, memEntities);
      entityInsurances = entityInsurances.map((insurance) => {
        const copied = { ...insurance };
        copied.owned_by_another = false;
        copied.owned_by_entity = true;
        copied.calculated = insurance.cash_value;
        copied.show_cash_value = true;
        return copied;
      });
    }

    memberCashValueInsurances = memberCashValueInsurances.map((insurance) => {
      insurance.owned_by_another = false;
      insurance.owned_by_entity = false;
      return insurance;
    });
    memberCashValueInsurances = CalculateMultipleOwnerValues(
      selectedMembers,
      memberCashValueInsurances,
      'owner',
      true
    );

    let entityRollupInsurances = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupInsurances = GenerateRollupItems(
        selectedMemIds,
        rollupEntities,
        entityInsurances,
        allInstrumentCategories,
        'insurance',
        'south',
        expandAllRollup
      );
      let rollupInstruments = GetEntityInstruments(
        entityInsurances,
        rollupEntities
      );
      entityInsurances = entityInsurances.filter((ins) => {
        if (!rollupInstruments.some((i) => i.id === ins.id)) {
          return ins;
        }
      });
    }
    let finalLiabilities = memberLiabilities.filter(
      (liability) => !mergedAssetIds.includes(liability.associated)
    );
    entityLiabilities = entityLiabilities.filter(
      (liability) =>
        ![...entityAssetsIds, ...mergedAssetIds].includes(liability.associated)
    );
    const southEastItems = GenerateSouthEastItems(
      membersList,
      entityList,
      assets,
      liabilities,
      insurances,
      selectedMembers,
      amrShowEntity,
      amrShowCustodian,
      showEntityRollup,
      allInstrumentCategories,
      expandAllRollup
    );
    const eastLiabilities = southEastItems.reduce((acc, curr) => {
      if (curr.hasOwnProperty('linked_items')) {
        const linkedItemsIds = curr.linked_items.map((item) => item.id);
        return [...acc, ...linkedItemsIds];
      }
      return acc;
    }, []);
    const southWestItems = GenerateSouthWestItems(
      membersList,
      entityList,
      assets,
      liabilities,
      insurances,
      selectedMembers,
      amrShowEntity,
      amrShowCustodian,
      showEntityRollup,
      allInstrumentCategories,
      expandAllRollup
    );
    const westLiabilities = southWestItems.reduce((acc, curr) => {
      if (curr.hasOwnProperty('linked_items')) {
        const linkedItemsIds = curr.linked_items.map((item) => item.id);
        return [...acc, ...linkedItemsIds];
      }
      return acc;
    }, []);
    finalLiabilities = finalLiabilities.filter(
      (liability) =>
        ![...eastLiabilities, ...westLiabilities].includes(liability.id)
    );
    entityLiabilities = entityLiabilities.filter(
      (liability) =>
        ![
          // ...memberLiabilityIds,
          ...eastLiabilities,
          ...westLiabilities,
        ].includes(liability.id)
    );
    let entityRollupLiabilities = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      const allEntityLiabilities = GetEntityInstruments(
        liabilities,
        rollupEntities
      );
      entityRollupLiabilities = GenerateRollupItems(
        selectedMemIds,
        rollupEntities,
        allEntityLiabilities,
        allInstrumentCategories,
        'liability',
        'south',
        expandAllRollup
      );
      let rollupInstruments = GetEntityInstruments(
        allEntityLiabilities,
        rollupEntities
      );
      entityLiabilities = entityLiabilities.filter((lia) => {
        if (!rollupInstruments.some((i) => i.id === lia.id)) {
          return lia;
        }
      });
      updatedAssets = updatedAssets.map((asset) => {
        const copied = { ...asset };
        const linked = asset?.linked_items.filter(
          (item) => !item.owned_by_entity
        );
        copied.linked_items = linked;
        return copied;
      });
    }
    finalLiabilities = finalLiabilities.map((liability) => {
      const updated = { ...liability };
      updated.owned_by_another = false;
      updated.owned_by_entity = false;
      return updated;
    });
    finalLiabilities = CalculateMultipleOwnerValues(
      selectedMembers,
      finalLiabilities
    );

    let groupedInsurances = SortInstrumentPriority([
      ...memberCashValueInsurances,
      ...entityInsurances,
      ...entityRollupInsurances,
    ]);
    let groupedLiabilities = SortInstrumentPriority([
      ...finalLiabilities,
      ...entityLiabilities,
      ...entityRollupLiabilities,
    ]);
    const groupedAssets = SortInstrumentPriority([
      ...updatedAssets,
      ...entityAssets,
      ...entityRollupAssets,
    ]);
    //LINKED LIABILITY CHANGES
    groupedInsurances = groupedInsurances.map((insurance) => {
      const updated = { ...insurance };
      if (!insurance.is_rollup_parent) {
        const linked = groupedLiabilities.filter(
          (liability) => liability.associated === insurance.id
        );
        updated.linked_items = linked.map((item) => {
          return { ...item };
        });
      }
      return updated;
    });
    const insuranceIds = groupedInsurances.map((item) => item.id);
    groupedLiabilities = groupedLiabilities.filter(
      (liability) => !insuranceIds.includes(liability.associated)
    );
    //LINKED LIABILITY CHANGES

    return [...groupedInsurances, ...groupedLiabilities, ...groupedAssets];
  }
};
