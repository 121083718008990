import { groupBy } from 'lodash';
import { AddInstrumentPriority, GenerateInstrumentType } from '../transformers';
import { GetSingleEntityInstruments } from './filters';

export const GenerateRollupItems = (
  selectedMembers,
  entities,
  allEntityInstruments,
  categories,
  type,
  region,
  expandAllRollup = false
) => {
  //If region is not north and type is insurance amount is cash_value
  const northRegions = ['north', 'northJoint', 'northLeft', 'northRight'];
  const isCashValue = type === 'insurance' && !northRegions.includes(region);

  //Need to handle if joint
  const groupedByEntityInstruments = entities.reduce((acc, curr) => {
    const entityInstruments = GetSingleEntityInstruments(
      allEntityInstruments,
      curr
    );
    const ownerRatio = curr.owners.reduce((acc, curr) => {
      if (selectedMembers.includes(curr.id)) {
        return (acc += parseFloat(curr.ratio));
      }
      return acc;
    }, 0);
    const ownerPercent = ownerRatio / 100;
    if (entityInstruments.length !== 0) {
      const rollUpList = [];
      const grouped = groupBy(entityInstruments, 'category');
      for (const [key, value] of Object.entries(grouped)) {
        const linkedItems = value;
        const calculatedLinkedItems = linkedItems.map((item) => {
          const copied = { ...item };
          const matchingEntity = item.members.find(
            (mem) => mem.relation === 'owner' && mem.id === curr.id
          );
          const entityRatio = matchingEntity?.ratio;
          const entityPercent = entityRatio / 100;
          if (isCashValue) {
            copied.show_cash_value = true;
            copied.amount = item.cash_value * ownerPercent * entityPercent;
          } else {
            copied.amount = item.amount * ownerPercent * entityPercent;
          }
          copied.linked_items = null;
          copied.calculated = null;
          // copied.split_percent = entityRatio;
          return copied;
        });
        const amount = calculatedLinkedItems.reduce((acc, curr) => {
          acc += curr.amount;
          return acc;
        }, 0);
        const priority = AddInstrumentPriority({ category: key });
        rollUpList.push({
          id: `${curr.id}_${key}`,
          entity_id: curr.id,
          category: key,
          name: curr.name,
          percent: ownerRatio,
          source: GenerateInstrumentType(key, categories),
          linked_items: calculatedLinkedItems,
          is_rollup_parent: true,
          instrument_type: type,
          is_expanded: expandAllRollup,
          show_cash_value: isCashValue,
          amount: amount,
          group: region,
          priority: priority + 0.5,
        });
      }
      return [...acc, rollUpList];
    }
    return acc;
  }, []);
  return groupedByEntityInstruments.flat();
};
