// import { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
// import { DropdownMenu } from '../';
import { colors } from '../../styles/variables';
// import { UseOutsideClick } from '../../utils';
import // customReport,
// customSurvey1,
// customSurvey2,
// customSurveyReport1,
// customSurveyReport2,
'../../assets';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { DateFromNowUTC } from '../../utils';

const SurveyListItem = ({
  isLoading = false,
  isHistory = false,
  // surveyNumber = 1,
  survey,
  actions = [],
  actionOnClick,
  // showMoreMenu,
  // setShowMoreMenu,
}) => {
  // const ref = useRef();
  // const moreActionsList = actions.filter((action) => action.isMoreMenu);
  const actionsList = actions.filter(
    (action) => !action.isMoreMenu && !action.isStartIcon
  );
  const startAction = actions.find((action) => action.isStartIcon);

  // UseOutsideClick(ref, () => {
  //   if (showMoreMenu) {
  //     setShowMoreMenu(false);
  //   }
  // });

  const checkIsDisabled = (survey, action) => {
    switch (action.value) {
      case 'review':
      case 'pdf':
      case 'instance_pdf':
        if (isHistory) {
          if (!(survey.unfinished || survey.completed)) {
            return true;
          }
        } else {
          return !survey.completed;
        }
        break;
      //If survey.unfinished is false disable icon
      case 'complete':
        return !survey.unfinished;
      //If survey.pdf_available is false disable icon
      case 'blank':
        return !survey.pdf_available;
      case 'instance_complete':
        return survey.completed;
      case 'instance_review':
        break;
      default:
        return false;
    }
  };

  const generateDisabledLabel = (survey, action) => {
    if (action.value === 'complete') {
      if (!survey.completed) {
        return 'Questionnaire not started';
      }
    }
    return action.disabledLabel;
  };

  return isHistory ? (
    <SurveyRow>
      <SurveyItem width="20%">
        <FlexRow>
          {isLoading ? (
            <>
              <LoadingPlaceholder
                round="true"
                width="22px"
                height="22px"
                margin="0 15px 0 0"
              />
              <LoadingPlaceholder width="200px" />
            </>
          ) : (
            <>{survey.name}</>
          )}
        </FlexRow>
      </SurveyItem>
      {/* <SurveyItem width="8%">
        {isLoading ? <LoadingPlaceholder width="50px" /> : survey.version}
      </SurveyItem> */}
      <SurveyItem width="15%">
        {isLoading ? (
          <LoadingPlaceholder width="100px" />
        ) : (
          DateFromNowUTC(survey.created_utc, true)
        )}
      </SurveyItem>
      <SurveyItem width="15%">
        {isLoading ? (
          <LoadingPlaceholder width="100px" />
        ) : survey.last_response_utc ? (
          DateFromNowUTC(survey.last_response_utc, true)
        ) : (
          '-'
        )}
      </SurveyItem>
      <SurveyItem width="15%">
        {isLoading ? (
          <LoadingPlaceholder width="100px" />
        ) : (
          `${survey.percent_complete}%`
        )}
      </SurveyItem>
      <SurveyItem width="20%">
        {isLoading ? (
          <LoadingPlaceholder width="100px" />
        ) : survey.completed && survey.description ? (
          survey.description
        ) : (
          '-'
        )}
      </SurveyItem>
      <SurveyItem width="15%">
        <ActionsContainer>
          {actionsList.map((action, index) => {
            return isLoading ? (
              <LoadingPlaceholder
                key={index}
                width="22px"
                height="22px"
                round="true"
                margin="0 0 0 13px"
              />
            ) : (
              <Tooltip
                key={index}
                title={
                  checkIsDisabled(survey, action)
                    ? action.disabledLabel
                    : action.label
                }
                position="left"
                trigger="mouseenter click"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
                followCursor={false}
              >
                <IconContainer
                  disabled={checkIsDisabled(survey, action)}
                  onClick={() =>
                    checkIsDisabled(survey, action)
                      ? null
                      : actionOnClick(action.value, survey)
                  }
                >
                  <img
                    src={action.icon}
                    alt={action.value}
                    data-image={action.value}
                    data-disabled={checkIsDisabled(survey, action)}
                  />
                </IconContainer>
              </Tooltip>
            );
          })}
        </ActionsContainer>
      </SurveyItem>
    </SurveyRow>
  ) : (
    <SurveyRow padding="20px 0">
      <SurveyItem width="30%">
        <FlexRow iconWidth="26px">
          {startAction && (
            <ActionsContainer>
              {isLoading ? (
                <LoadingPlaceholder
                  width="22px"
                  height="22px"
                  round="true"
                  margin="0 13px 0 0"
                />
              ) : (
                <Tooltip
                  title={
                    checkIsDisabled(survey, startAction)
                      ? startAction.disabledLabel
                      : startAction.label
                  }
                  position="right"
                  trigger="mouseenter click"
                  delay={0}
                  arrow={true}
                  arrowSize={'small'}
                  animation={'fade'}
                  animateFill={false}
                  transitionFlip={false}
                  size={'small'}
                  followCursor={false}
                >
                  <IconContainer
                    disabled={checkIsDisabled(survey, startAction)}
                    margin={'0 10px 0 0'}
                    onClick={() =>
                      checkIsDisabled(survey, startAction)
                        ? null
                        : actionOnClick(startAction.value, survey)
                    }
                  >
                    <img
                      src={startAction.icon}
                      alt={startAction.value}
                      data-image={startAction.value}
                      data-disabled={checkIsDisabled(survey, startAction)}
                    />
                  </IconContainer>
                </Tooltip>
              )}
            </ActionsContainer>
          )}
          {isLoading ? (
            <>
              <LoadingPlaceholder width="200px" />
            </>
          ) : (
            <>{survey.name}</>
          )}
        </FlexRow>
      </SurveyItem>
      {/* <SurveyItem width="10%">
        {isLoading ? <LoadingPlaceholder width="50px" /> : survey.version}
      </SurveyItem> */}
      <SurveyItem width="13%">
        {isLoading ? (
          <LoadingPlaceholder width="100px" />
        ) : survey.sent ? (
          DateFromNowUTC(survey.sent_utc, true)
        ) : (
          '-'
        )}
      </SurveyItem>
      <SurveyItem width="13%">
        {isLoading ? (
          <LoadingPlaceholder width="100px" />
        ) : survey.completed ? (
          DateFromNowUTC(survey.completed_utc, true)
        ) : (
          '-'
        )}
      </SurveyItem>
      <SurveyItem width="24%">
        {isLoading ? (
          <LoadingPlaceholder width="150px" />
        ) : survey.completed_description ? (
          survey.completed_description
        ) : (
          '-'
        )}
      </SurveyItem>
      <SurveyItem width="20%">
        <ActionsContainer>
          {actionsList.map((action, index) => {
            return isLoading ? (
              <LoadingPlaceholder
                key={index}
                width="22px"
                height="22px"
                round="true"
                margin="0 0 0 13px"
              />
            ) : (
              <Tooltip
                key={index}
                title={
                  checkIsDisabled(survey, action)
                    ? generateDisabledLabel(survey, action)
                    : action.label
                }
                position="left"
                trigger="mouseenter click"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
                followCursor={false}
              >
                <IconContainer
                  disabled={checkIsDisabled(survey, action)}
                  onClick={() =>
                    checkIsDisabled(survey, action)
                      ? null
                      : actionOnClick(action.value, survey)
                  }
                >
                  <img
                    src={action.icon}
                    alt={action.value}
                    data-image={action.value}
                    data-disabled={checkIsDisabled(survey, action)}
                  />
                </IconContainer>
              </Tooltip>
            );
          })}
          {/* {!isLoading && showMoreMenu === survey?.id && (
            <DropdownMenu
              showMoreMenu={showMoreMenu}
              setShowMoreMenu={setShowMoreMenu}
              heading="More Actions"
              items={moreActionsList}
              passedData={survey}
              customStyle={{ right: '-4px', width: '170px' }}
            />
          )} */}
        </ActionsContainer>
      </SurveyItem>
    </SurveyRow>
  );
};

const SurveyRow = styled.div`
  padding: 15px 0;
  padding: ${(props) => (props.padding ? props.padding : '15px 0')};
  height: 25px;
  display: flex;
  font-size: 14px;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const SurveyItem = styled.div`
  flex: 1 1 auto;
  width: ${(props) => (props.width ? props.width : '100%')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  padding: 0 5px;
`;

const FlexRow = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  img {
    width: ${(props) => (props.iconWidth ? props.iconWidth : '22px')};
    height: ${(props) => (props.iconWidth ? props.iconWidth : '22px')};
    /* margin-right: 15px;
    margin-bottom: 2px; */
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 3px')};
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) => (props.disabled ? 'none' : colors.lightGrey)};
  }
  img {
    width: ${(props) => (props.iconWidth ? props.iconWidth : '18px')};
    height: ${(props) => (props.iconWidth ? props.iconWidth : '18px')};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`;

SurveyListItem.propTypes = {
  isLoading: PropTypes.bool,
  isHistory: PropTypes.bool,
  // surveyNumber: PropTypes.number,
  survey: PropTypes.object,
  actions: PropTypes.array,
  actionOnClick: PropTypes.func,
  // showMoreMenu: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // setShowMoreMenu: PropTypes.func,
};

export default SurveyListItem;
